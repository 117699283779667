<template>
  <div v-if="workInProgress">
    <h1>WORK IN PROGRESS</h1>
  </div>
  <div v-else>
    <CRow>
      <CTableAccountList
        :items="listAccount"
        striped
        caption="Account list"
        :loading="listAccountLoading"
      />
    </CRow>
  </div>
</template>

<script>
import CTableAccountList from "./TableAccountList.vue";

export default {
  name: "AccountsDashboard",
  components: { CTableAccountList },
  data() {
    return {
      workInProgress: false,
      listAccount: undefined,
      listAccountLoading: true,
      listRequest: undefined,
      unsubscribe: undefined
    };
  },
  async created() {
    var accessToken = await this.$auth.getAccessToken();
    if (
      this.$store.getters["portalParametersModule/accountWorkInProgress"] ===
      undefined
    ) {
      await this.$store.dispatch("portalParametersModule/loadWipParameters", {
        refresh: true,
        accessToken: accessToken
      });
    }
    this.workInProgress = this.$store.getters[
      "portalParametersModule/accountWorkInProgress"
    ];
    if (!this.workInProgress) {
      if (this.$store.state.userinfo != undefined) {
        this.loadAccountList();
      }
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setUserInfoState") {
        console.log('mutation.type === "setUserInfoState"');
        if (state.userinfoReady) {
          console.log("loading account list in subscribe");
          this.loadAccountList();
        }
      }
    });
  },
  // async created() {
  //   this.workInProgress = this.$store.getters[
  //     "portalParametersModule/accountWorkInProgress"
  //   ];
  //   if (this.$store.state.userinfo != undefined) {
  //     this.loadAccountList();
  //   }
  //   this.unsubscribe = this.$store.subscribe((mutation, state) => {
  //     if (mutation.type === "setUserInfoState") {
  //       console.log('mutation.type === "setUserInfoState"');
  //       if (state.userinfoReady) {
  //         console.log("loading account list in subscribe");
  //         this.loadAccountList();
  //       }
  //     }
  //   });
  // },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    loadAccountList() {
      var manage = this.$getAccountList("owner").map(item => {
        return { ...item, manage: true };
      });
      this.$getAccountList("admin").forEach(item => {
        if (manage.findIndex(el => el.AccountId === item.AccountId) == -1)
          manage.push({ ...item, manage: false });
      });

      this.listAccount = manage;
      this.listAccountLoading = false;
      console.log("dsfsf" + JSON.stringify(this.listAccount));
    }
  }
};
</script>
